<template>
	<div id="Smart0">
		<Header :navbg='true'></Header>
		<div class="spot-wrap">
			<img class="iframe-image" src="../../../assets/images/smart0/smart0.jpg" v-if="browser=='Edge'" >
			<iframe ref="iframe" src="https://app.modaiyun.com/damf/rendering-viewer/pano/LxXcz7uWsx?isRoaming=true" frameborder="0"
				style="width: 100%;height: 100%;" v-else></iframe>
			<div class="btn-wrap">
				<van-button @click="followTap" round color="">关注订制</van-button>
			</div>
		</div>
<!-- 		<div class="main-wrap">
			<div class="main-title">
				<img src="../../../assets/images/smart0/smart0-logo.png" alt="">
				零碳智慧住宅
			</div>
			<div class="main-subtitle">
				集自主绿能、科技与美感为一体的未来城市住宅
			</div>
			<div class="lightspot">
				<i><a href="#section0" rel="external nofollow" @click="scrollToAnchor">自主绿能</a></i>
				<i><a href="#section1" rel="external nofollow" @click="scrollToAnchor">智慧恒温</a></i>
				<i><a href="#section2" rel="external nofollow" @click="scrollToAnchor">生态庭院</a></i>
				<i><a href="#section3" rel="external nofollow" @click="scrollToAnchor">绿色出行</a></i>
			</div>
			<div class="btn-wrap">
				<van-button @click="followTap" round color="">关注订制</van-button>
			</div>
		</div> -->
		<div class="spot-wrap" :id="'section'+index" v-for="(item,index) in lightspotList" :key="index">
			<div class="aaa">
				<div class="title">{{item.name}}</div>
				<img class="line-img" src="../../../assets/images/smart0/line.png" alt="">
				<div class="" v-for="(desItem,desIndex) in item.desList" :key="desIndex">
					<div class="subname">{{desItem.subname}}</div>
					<div class="desc">{{desItem.des}}</div>
				</div>
			</div>
			<div class="aaa">
				<img class="spot-img" :src="item.image" alt="">
				<van-button @click="followTap" round color="#393C41" plain>关注订制</van-button>
			</div>
		</div>
		<Footer />
	</div>
</template>
<script>
	import utils from '@/common/utils'
	import Header from '@/components/head2022'
	import Footer from '@/components/foot2022'
	import {myBrowser} from '@/common/app.js';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				browser:'',
				lightspotList: [{
						image:require('../../../assets/images/smart0/green-house.png'),
						name: "Smart0 自主绿能",
						desList: [{
								subname: '光伏建筑一体化设计',
								des: '来自太阳能光伏发电，具有建筑构件和建筑材料的功能，与建筑物形成完美的统一体。'
							},
							{
								subname: '每户≥50㎡光伏屋面',
								des: '实现绿色、环保、清洁电力，自给自足、用电自由，还可向外配送电力。'
							},
						]
					},
					{
						image:require('../../../assets/images/smart0/constant-house.png'),
						name: "Smart0 智慧恒温",
						desList: [{
							subname: '智慧恒温系统',
							des: '全年室温20℃-26℃，人体舒适范围内的最佳温度。'
						}, ]
					},
					{
						image:require('../../../assets/images/smart0/ecology-house.png'),
						name: "Smart0 生态庭院",
						desList: [{
							subname: '自然共生系统',
							des: '绿色植物、光伏与后院停车位相互融合，形成独特的双庭院，一个养花，一个停车。'
						}, ]
					},
					{
						image:require('../../../assets/images/smart0/green-travel.png'),
						name: "Smart0 绿色出行",
						desList: [{
							subname: '出行管理系统',
							des: '利用光伏发电为新能源汽车充电，实现真正的绿色出行。'
						}, ]
					},
					{
						image:require('../../../assets/images/smart0/future-house.png'),
						name: "未来城市住宅",
						desList: [{
							subname: '',
							des: '从我国每平方公里居住一万人的新城规划来看，居住用地为25~40万平米，零碳智慧住宅小区是2-3层住宅，按照1.0~1.2的容积率建设，即可满足一万人居住，因此适合在我国所有城市居住区建设，让人人共享绿色环保、健康智能的建设成果。'
						}, ]
					}
				],
			}
		},
		created() {
			this.browser=myBrowser();
		},
		methods: {
			followTap() {
				this.$router.push({
					path: 'smart0-intention'
				});
			},
			scrollToAnchor(event) {
				const href = event.target.getAttribute('href');
				window.location.hash = href;
			},
		}
	}
</script>
<style lang="scss">
	#Smart0 {
		.van-button--round {
		    width: 50%;
			height: 32px;
			font-size: 12px
		}
		.btn-wrap {
			position: absolute;
			bottom: 50px;
			width: 100%;
			display: flex;
			justify-content: center;
		}
		.main-wrap {
			color: #FFFFFF;
			background: url('../../../assets/images/smart0/headbg.png') no-repeat;
			width: 100%;
			height: 100vh;
			background-size: 100% 100%;
			background-position: center center;
			text-align: center;
			
			.main-title {
				font-size: 24px;
				font-weight: bold;
				line-height: 24px;
				padding-top: 84px;
				img {
					height: 24px;
				}
			}
			.main-subtitle {
				font-size: 12px;
				line-height: 20px;
				padding-top: 24px;
				font-weight: 800;
			}
	
			.lightspot {
				position: absolute;
				bottom: 170px;
				width: 34%;
				left: 33%;
				font-size: 12px;
				line-height: 12px;
				i{
					padding: 0 6px;
					display: inline-block;
					margin-bottom: 10px;
				}
				i:nth-child(odd){
					border-right: 1px solid #FFFFFF;
				}
			}
			
		}
		.spot-wrap {
			width: 100%;
			height: 100vh;
			color: #595959;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.iframe-image{
				width: 100%;
				height: 100vh;
			}
			.title {
				font-size: 24px;
				font-weight: 800;
				line-height: 24px;
				margin:60px 0 14px;
			}
			.line-img {
				width: 46px;
			}
			.spot-img{
				width: 100%;
			}
			.subname {
				font-size: 14px;
				font-weight: 800;
				line-height: 14px;
				margin: 17px 0 14px;
			}
			.desc {
				font-size: 12px;
				line-height: 18px;
				padding: 0 15px;
				text-align: left;
			}
			.van-button--round {
			    margin: 30px 0 20px;
			}
		}
	}
</style>